export default {
    comm: {  //共用     
        back: '返回',
        messagetitle: '單據資訊',
        detailtitle: '詳細資訊',
        login: '登入',
        default: '首頁',
        order: '訂單',
        ordersearch: '訂單查詢',
        replydeliverytime:'回覆交期',
        orderdetailsearch:'訂單明細查詢',
        orderchangesearch:'訂單變更查詢',
        orderdetail: '訂單詳情',
        orderdetailsearch:'訂單詳情查詢',
        orderchangesearch:'訂單變更查詢',
        replydeliverytime:'回覆交期',
        deliver: '發貨',
        delivercar: '發貨車',
        deliversearch: '發貨查詢',
        deliverdetail: '發貨單詳情', 
        deliverdetailEdit: '發貨單-修改',
        returnQuery: '退貨查詢',
        returnDetail: '退貨單詳情',
        reconciliationsearch: '對帳查詢',
        reconciliationdetail: '對帳單詳情',
        ARSearch: '應收查詢',
        report: '報表',
        orderexecution: '訂單執行狀況',
        trackingProducts:'發貨產品入庫追蹤',
        inspectionresults: '檢驗結果',
        supplyassessment: '供貨考核表',
        deliverdelay: '交貨延遲分析表',
        operationmenu: '操作選單',
        download: '下載中心',
        sortfile: '分類文件',
        inform:'通知中心',
        downloadprint: '進行中訂單圖紙下載',
        help: '幫助中心',
        bulletin:'公告',
        company: '廈門正航軟體科技有限公司',
        number: '序',
        startdate: '開始日期',
        enddate: '結束日期',
        alltype: '所有類別',
        tip: '提示',
        tipContent:'提示內容',
        message:'消息',
        unselectwarning:'請至少選擇一項',
        viewmore:'查看更多',
        confirm:'確定',
        updatePassword:'修改密碼成功',
        iswithtax:'是否含稅',
        withtaxState:'含稅',
        includetax:'單價含稅',
        containUnRelease:'納入結案訂單',
        btnyes:'是',
        btnno:'否',
        selectexport:'請選擇一個拋出格式',
        selectprint:'請選擇一個列印格式',
        uploadFile:'上傳附件',
        chooseFile:'選擇文件',
        uploadConfirmText:'確認上傳',
        uploadTiptxt:'將單個文件（不超過20M）拖到此處，或',
        uploadClick:'點擊上傳',
        uploadSuccess:'上傳成功！',
        deleteSuccess:'刪除成功！',
        cancelDelete:'已取消刪除',
        deleteFileTip:'將刪除該附件，是否繼續？',
        allFileType:'所有文件',
        fileTypeListTxt:'支援上傳格式：',
        fileTypeList:'doc、docx、xlsx、xls、ppt、pptx、txt、jpg、png、gif、bmp、zip、rar、7z、pdf、xmind',
        fileSizeTip:'文件過大,請上傳小於等於20M的文件!',
        fileSpecialCharTip:'文件有特殊字符,文件名中不可存在特殊字符,請重新上傳!',
        fileMaxNumberTip:'文件上傳數量已達上限!',
        fileNullTip:'請選擇要上傳的文件!',
        allowFileNumberTip:',最多可同時上傳3個附件!',
        cancelText:'取消',
        copyClick:'點擊複製詳情',
        copyFinished:'已複製到剪切板！',
        copyError:'當前瀏覽器暫不支援自動複製，請手動選擇複製！'
    },
    button: { //按鈕
        repeatAddText:'繼續添加',
        confirm: '確認簽收',
        veto: '否決',
        download: '下載圖紙',
        downloadchange: '下載變更圖紙',
        downloadBillAccess:'下載訂單附件',
        export: '拋出',
        print: '列印',
        reset: '重 置',
        query: '查 詢',
        loadMore:'展開更多條件',
        hideMore:'收起更多條件',
        check: '查看',
        affirm: '確認',
        Cancel:'取消',
        cancel: '取 消',
        determine: '確 定',
        modification:'修改',
        delete: '刪除',
        save:'保存',
        addnew:'添加',
        replyDeliveryDate:'回覆交期',
        batchconfirmation: '批量確認',
        batchshipment: '批量發貨',
        batchexport: '批量拋出',        
        batchPrint: '批量列印',
        exporttitle: '批量拋出訂單',
        confirmationText: '訂單已簽收並確認',
        exportTextA: '拋出選中數據',
        exportTextB: '拋出滿足條件數據',
        printTextA:'列印選中數據',
        printTextB:'列印滿足條件數據',
        vetoreason: '請輸入否決原因',
        orderveto: '訂單否決',
        upload: '轉入',
        uploadtem: '轉入模板下載',
        deletechoose: '刪除選中產品',
        search: '搜 索',
        modifyaddress:'批量修改地址',
        modifyRowAddress:'修改地址',
        addnew:'新增'
    },
    Login: { //登入頁
        title: '正航供應商平台',
        database: '賬套',
        userId: '帳戶',
        password: '密碼',
        rempsaaword: '記住密碼',
        login: '登入',
        environment: '環境配置',
        InstallPlugin: '擴充功能',
        HardwareInfo: '硬體資訊',
        DownloadCenter: '下載中心',
        databaseText: '賬套不允許為空！',
        userIdText: '帳戶不允許為空！',
        passwordText: '密碼不允許為空！',
        prompt: '提示',
        failure: '登入失敗！',
        userExist: '當前帳號已經在其他地方登入，如果您強制登入對方將登出，是否強制登入？',
        alreadyLogined: '當前瀏覽器已經有一個登入用戶（帳套:{0}, 帳號:{1}).如果您強制登入，此用戶將被登出，請確認是否登入？',
        screentip:'建議使用分辨率:寬1360以上,以獲得最佳體驗',
        loginload:'登入中',
        cookieload:'等待伺服器啟動中...',
        chromeoutdate:'當前瀏覽器為谷歌內核，且谷歌內核版本較低，為更好的使用供應商平台，請及時將本瀏覽器升級至最新版本。',
        firefoxoutdate:'當前瀏覽器為火狐內核，且火狐內核版本較低，為更好的使用供應商平台，請及時將本瀏覽器升級至最新版本。',
        outdateconfirm:'我已悉知',
        outdatecancel:'三十天內不再提示',
        webVersion:'版本'
    },
    Default: { //首頁
        phonenumber: '客服服務熱線',
        increaseOrder: '本月新增訂單',
        dealOrder: '本月發貨筆數',
        refundOrder: '本月退貨筆數',
        list: '待辦事項',
        confirmnumber: '待確認筆數',
        delivernumber: '待發貨筆數',
        syspublic: '[系統公告]',
        sysupdate: '[系統更新]',
        newestdownload:'最新下載',
        nonewestdownload:'暫無最新下載',
        bulletin:'公告',
        nobulletin:'暫無公告',
        information:'消息區',
        noinformation:'暫無消息',
        printhavenew:'圖紙有變更，請及時下載',
        printnochange:'暫無訂單有圖紙變更',
        haveaneworder:'有一筆新的訂單[{0}],請及時確認',
        ConfirmState0:'訂單交期有變更，請及時確認[{0}]', // 未變更（採購訂單變更處理狀況）
        ConfirmState2:'回覆新交期客戶不同意,請及時確認[{0}]', // 不同意變更
        ConfirmState3:'回覆新交期客戶已同意，請查收[{0}]', // 已變更
        withoutneworder:'暫無新的訂單',
        haveanewchange:'訂單[{0}]有變更,請及時確認',
        withoutnewchange:'暫無訂單有變更'
    },
    Home: {
        changepassword: '修改密碼',
        billsetting: '單據設定',
        register: '登入資訊',
        delivercar: '發貨車',
        back: '退出',
        oldpassword: '舊密碼：',
        newpassword: '新密碼：',
        confirmpassword: '確認密碼：',
        oldpswpls: '請輸入舊密碼',
        newpswpls: '請輸入新密碼',
        againpsw: '請再次輸入密碼',
        errpsw: '兩次輸入密碼不一致!',
        symbolerr: '密碼中不可包含特殊字符“<”或“>”',
        logininfo:'登入資訊',
        createtime:'創建時間',
        lastlogindate:'登入時間',
        ipaddress:'本次登入IP',
        membertype:'用戶類型',
        commonuser:'普通用戶',
        tempuser:'臨時帳戶',
        client:'客戶',
        supplier:'供應商',
        unknow:'未知',
        toolbarShowStyle:'工具列顯示方式',
        showIcon:'只顯示圖標',
        showIconAndText:'顯示圖標和文本',
        showText:'只顯示文本',
        fieldRawTextType:'欄位顯示風格',
        showcodeandname:'顯示代碼和名稱',
        showname:'顯示名稱',
        showcode:'顯示代碼',
        billsettingsucess:'單據設定成功',
        oldpwderror:'密碼框內內容不得為空'
    },
    Order: { //訂單
        messagenumber: '單據編號',
        messagedate: '單據日期',
        compName:'客戶',
        inputpls: '請輸入',
        inputchoose: '請選擇',
        checkstate: '確認狀態',
        goodsstate: '發貨狀態',
        confirmStateTxt: '客戶意見', // 對應採購訂單表身的【交期變更處理狀況】
        confirmState1:'未確認', // 未確認：對應後台【未變更】【供應商要求變更】
        confirmState2:'不同意修改交期', // 不同意修改交期：對應後台【不同意變更】
        confirmState3:'同意修改交期', // 同意修改交期：對應後台【已變更】
        amount: '總金額',
        operation: '操作',
        unshipped: '未發貨',
        partialDel: '部分發貨',
        shipped: '已發貨',
        allshipments: '全部發貨',
        unconfirmed: '未確認',
        unconfirmedchanges: '有變更未確認',
        confirm: '已簽收確認',
        unsignedconfirmation: '未簽收確認',
        confirmerr:"訂單{0}因“不屬於當前組織”或“不處於生效狀態”導致確認失敗",
        confirmerr2:"訂單{0}確認失敗",
        vetoorder:"訂單已否決",
        billOrgIdErr:"存在不同單據組織[{0}]，不允許執行批次列印",
        billTypeIdErr:"存在不同單據類型[{0}]，不允許執行批次列印",
        exportPlaceHolder:'請選擇拋出格式',
        exportNoneholder: '未配置拋出格式',
        nodata:'沒有符合當前條件的數據'

    },
    OrderVary: { //變更單查詢
        addnew:'新增',
        update:'修改',
        delete:'刪除',
        searchVaryData:'變更查詢',
        noData:'暫無變更單數據',
        billNo: '變更單號',
        purBillNo: '採購訂單號',
        compName:'客戶',
        currId:'幣別',
        rowNo:'序號',
        rowCode:'標識號',
        varyKind:'變更方式',
        materialId:'產品代碼',
        materialName:'產品名稱',
        materialSpec:'產品規格',
        sQuantity:'交易數量',
        quantity:'基本數量',
        presentSQty:'免費數量',
        sPrice:'交易價',
        oAmountWithTax:'含稅金額',
        deliveryDate:'交貨日期',
        address:'收貨地址',
        remark:'備註',
        rowState:'行項狀態',
        rowStateName1:'正常',
        rowStateName2:'終止',
        rowStateName3:'轉單凍結',
        varyField:'變更後',
        unVaryField:'變更前'
    },
    OrderDetail: { //訂單詳情
        downloadBillAccess:'下載訂單附件',
        rowNo:'序號',
        rowCode:'標識號',
        messagenumber: '單號',
        messagedate: '日期',
        compName: '客戶',
        amount: '金額',
        checkstate: '確認狀態',
        goodsstate: '發貨狀態',
        purchaser: '採購員',
        OAmountWithTax:'含稅金額',
        remark: '備註',
        receiveOrgName: '收貨組織',
        number: '序',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        changenumber: '交易數量',
        Quantity:'基本數量',
        freenumber: '免費數量',
        nonumber: '未轉數量',
        price: '交易價',
        taxamount: '含稅金額',
        deldate: '交貨日期',
        address: '收貨地址',
        rowState:'行項狀態',
        tip: '列印提示:請選擇報表文件',
        placeholder: '請選擇報表格式',
        noneholder: '未配置報表列印格式',
        orderconfirmed:"訂單已確認",
        orderauthority:"訂單{0}沒有查看權限，請聯絡管理員"
    },
    OrderDetailSearch: { //訂單詳情查詢
        txtBillNo: '單據編號',
        txtCompName: '客戶',
        txtRowNo: '序號',
        txtBillDate: '單據日期',
        txtMaterialId: '產品代碼',
        txtMaterialName: '產品名稱',
        txtMaterialSpec: '產品規格',
        txtSQuantity: '交易數量',
        txtQuantity: '基本數量',
        txtTotalSQty: '總數量',
        txtUnTransSQty: '未轉數量',
        txtSPrice: '交易價',
        txtOAmountWithTax: '含稅金額',
        txtDeliveryDate: '交貨日期',
        txtMTONo: 'MTO',
        txtBatchNo: '批號',
        txtBillState: '發貨狀態',
        txtRowState: '行項狀態',
        txtRowState1: '正常',
        txtRowState2: '終止',
        txtRowState3: '轉單凍結',
        txtRemark: '備註',
        receiveOrgName: '收貨組織',
        errcurrentstate:'勾選單據{0}的單據已結案，無法進行發貨，請重新勾選。'
    },
    Deliver: { //發貨
        loadAllBill:'顯示全轉發貨車訂單行項',
        prodId: '單號/產品代碼',
        prodName: '產品名稱',
        compName: '客戶',
        address: '送貨地址',
        inputpls: '請輸入',
        deliverDate: '交貨日期',
        mtoNo:'MTO',
        batchNo:'批號',
        nearthreeday: '近3天',
        nearsevday: '近7天',
        nearthirtyday: '近30天',
        custom: '自定義',
        choosedate: '選擇日期',
        OrderNo: '採購單號',
        ProdName: '產品名稱',
        ProdId: '產品代碼',
        ProdSpec: '產品規格',
        CarQuantity: '已轉發貨車數量',
        SQuantity: '交易數量',
        TranQty: '已轉數量',
        DeliverDate: '交貨日期',
        UnTransSQty: '未轉數量',
        Quantity: '發貨數量',
        TotalQty:'發貨基本數量',
        UnitName:'基本單位',
        remark: '備註',
        address: '收貨地址',
        allCheck: '全選',
        checkNum: '共選擇',
        number: '筆',
        total:'總計{0}筆',
        addToCar: '加入發貨車',
        deliver: '直接發貨',
        message: '請輸入正確的發貨數量',
        success: '已成功加入發貨車',
        warning: '請至少選擇一項',
        resetnow:'重置當前單據',
        resetdefault:'重置為預設',
        unabledelivertip:'提示:單號{0}含稅狀態與當前頁面過濾條件不同，已被過濾',
        upload:'點擊上傳',
        limitupload:'只能上傳xlsx,xls文件',
        unabledelivertip2:"請檢查訂單{0}是否關鍵資訊有誤或者未轉數量為0!",
        excellimittip:"請上傳文件格式為.xlsx,.xls的Excel文件",
        excelemptytip:"請上傳表格內容不為空的文件",
        difforgaddress:'所選行項中含有不同組織的單據，請重新選擇',
        pagesize:'筆/頁',
        exceldataerr:"轉入的excel文件{0}中數據與伺服器中的不匹配，已被過濾。",
        taxstateerr:"若被過濾單據中存在含稅狀態與當前頁面勾選不同的單據，請先切換勾選狀態後再重新進行轉入。",
        excelincomplete:"{0}的數據中，必填欄位資訊未填，已被過濾。",
        excelerrtitle:"Excel中部分數據已被過濾",
        excelerrno:" 第{0}行",
        FSQuantity:'發貨交易數量',
        BSQuantity:"發貨交易基本數量",
        PresentSQty:"免費數量",
        PresentQty:"免費基本數量",
   		addtocartaxstate:'勾選的行項中存在採購單號{0}為含稅，採購單號{1}為未稅，無法同時加入發貨車。'
    },
    DeliverCar: { //發貨車
        inputpls: '請輸入',
        OrderNo: '單號',
        ProdId: '產品代碼',
        ProdName: '產品名稱',
        ProdSpec: '產品規格',
        UnTransSQty: '未轉數量',
        MTONo:'MTO',
        BatchNo:'批號',
        remark: '備註',
        address: '收貨地址',
        operation: '操作',
        number: '數量',
        allCheck: '全選',
        checkNum: '共選擇',
        number: '筆',
        addToCar: '加入發貨車',
        deliver: '直接發貨',
        deleteMessage: '刪除成功',
        message: "請至少選擇一項",
        zerotip:'訂單{0}未轉數量為0，不可發貨，請及時移出發貨車',
        qzerotip:'訂單{0}筆數為0或為空，不可發貨，請輸入正確的數量',
        deliverloading:'提交訂單中',
        changeaddress:'修改地址中'
    },
    DeliverSuccess: { //發貨成功
        success: '發貨成功',
        billno: '發貨單號',
        print: '列印發貨單',
        check: '查看發貨單',
        texttip: '點擊發貨單號查看該單據詳情',
        totalcount:'總共選擇{0}筆產品',
        successcount:'成功提交{0}筆產品'
    },
    DeliverSearch: { //發貨查詢 
        messagenumber: '單據編號',
        messagedate: '單據日期',
        orgName: '客戶',
        inputpls: '請輸入',
        inputchoose: '請選擇',
        purchaser: '採購員',
        warestate: '入庫狀態',
        cancelstate: '退貨狀態',
        operation: '操作',
        nowarehousing: '未入庫',
        partialwarehousing: '部分入庫',
        allwarehousing: '全部入庫',
        unreturned: '未退貨',
        partialreturn: '部分退貨',
        fullreturn: '全部退貨',
        allaccrefund: '有帳款全退',
        isdelete:'是否要刪除？',
        FromBillNo:'來源單號'
    },
    DeliverDetail: { //發貨單詳情
        messagenumber: '單據編號',
        messagedate: '單據日期',
        orgName: '客戶',
        OAmountWithTax:'含稅金額',
        amount: '金額',
        warestate: '入庫狀態',
        cancelstate: '退貨狀態',
        purchaser: '採購員',
        receiveOrgName: '收貨組織',
        remark: '備註',
        number: '序',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        deliverynumber: '送貨數量',
        carrySQty:'送貨基本數量',
        receiptnumber: '收貨數量',
        receivingQty: '收貨基本數量',
        SQuantity: '交易數量',
        passnumber: '合格數量',
        nonumber: '不合格數',
        price: '交易價',
        subtotal: '小計',
        address:'收貨地址',
        numbertip:'請輸入正確的數量',
        editaddresses:'同個收貨申請單收貨地址需相同，請確認是否要修改地址',
        rowerrortip:'行項{0}存在異常數據，請正確填寫後再嘗試保存單據'
    },
    ReturnQuery: { //退貨查詢 
        billNo: '單據編號',
        billDate: '單據日期',
        orgName: '客戶',
        inputpls: '請輸入',
        inputchoose: '請選擇',
        purchaser: '採購人員',
        oAmountWithTax:'含稅金額',
        operation: '操作' 
    },
    ReturnDetail: { //退貨詳情
        billNo: '單據編號',
        billDate: '單據日期',
        orgName: '客戶',
        OAmountWithTax:'含稅金額',
        amount: '金額',
        warestate: '入庫狀態',
        cancelstate: '退貨狀態',
        purchaser: '採購員',
        remark: '備註',
        number: '序',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        deliverynumber: '送貨數量',
        receiptnumber: '退貨總數量',
        receivingQty: '退貨基本數量',
        SQuantity: '交易數量',
        presentSQty: '免費數量',
        passnumber: '合格數量',
        nonumber: '不合格數',
        price: '交易價',
        subtotal: '小計',
        backReasonName:'退貨原因',
        address:'收貨地址'
    },
    ReconciliationSearch: { //對帳查詢
        messagenumber: '單據編號',
        messagedate: '單據日期',
        compName:'客戶',
        inputpls: '請輸入',
        inputchoose: '請選擇',
        payment: '付款條款',
        taxamount: '含稅金額',
        remark: '備註',
        operation: '操作',
    },
    ReconciliationDetail: { //對帳單詳情
        messagenumber: '單號',
        messagedate: '日期',
        purchaser: '採購員',
        amount: '金額',
        taxes: '稅金',
        taxName:'稅碼',
        taxRate:'稅率(%)',
        taxamount: '含稅金額',
        conditionName:'付款條款',
        bill:'單據',
        PermitState_2:'已審核',
        accessoryType:'附件類型',
        canNotAddAccess:'未啟用審核後可異動，不可添加附件',
        canNotDeleteAccess:'未啟用審核後可異動，不可刪除附件',
        remark: '備註',
        number: '序',
        codeid: '產品代碼', 
        name: '產品名稱', 
        specifications: '產品規格', 
        pricenumber: '計價數量',
        price: '單價',
        presentQuantity: '免費數量',
        sourcetype: '來源單種類',
        sourcenumber: '來源單號',
        purnumber: '採購訂單'
    },
    ReplyDeliveryTime:{ //回覆交期
        rowNo:'序號',
        fromBillNo:'來源單號',
        confirmSQty:'答交數量',
        confirmDelDate:'答交日期',
        confirmResult:'備註',
        operation: '操作',
        pleaseEnter:'請輸入',
        tip:'回覆交期提示',
        successMsg:'答交成功！',
        dateNullMsg:'日期為空，請填寫！',
        dbNullMsg:'數據為空，請先添加再確認！'
    },
    ARSearch: { //應收查詢
        messagedate: '日期',
        billTypeAuto: '期初應付',
        typeNameYF: '應付',
        typeNameYS: '應收',
        typeNameYuF: '預付',
        typeNameYuS: '預收',
        inputpls: '請輸入',
        compName:'客戶',
        documenttype: '單據種類',
        messagenumber: '單據編號',
        date: '單據日期',
        preRecOAmount:'預收款',
        preRecOAmountCheckText:'顯示預收款',
        amountreceivable: '應收金額',
        currentamount: '本期核銷金額 ',
        discountamount: '本期折扣金額',
        lateamount: '未核銷金額',
        summary: '彙總',
        ShowUnZeroItem:'期初為零不顯示',
        ShowUnZeroEnd:'期末為零不顯示',
        FromBillNo:'來源訂單號'
    },
    InspectionResults: { //檢驗結果
        product: '產品',
        messagedate: '檢驗日期',
        sourcenumber: '來源訂單號',
        inputpls: '請輸入',
        inputPls: '請輸入產品代碼或名稱',
        inputchoose: '請選擇時間',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        deliverynumber: '送貨數量',
        receiptnumber: '收貨數量',
        passnumber: '合格數量',
        nonumber: '不合格數',
        result: '判定結果'
    },
    OrderExecution: { //訂單執行狀況查詢
        product: '產品',
        messagedate: '單據日期',
        order: '訂單',
        inputpls: '請輸入',
        inputPls: '請輸入產品代碼或名稱',
        inputchoose: '請選擇時間',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        MTONo:'MTO',
        BatchNo:'批號',
        changenumber: '交易數量',
        freenumber: '免費數量',
        allnumber: '總數量',
        price: '交易價',
        amount: '金額',
        taxamount: '含稅金額',
        receivingSQty:'送貨數量', // 取後台收貨數量
        deliverydate: '預發貨日',
        stockquantity: '已入庫量',
        cancelnumber: '退貨數量',
        shippedamount: '已發貨未對帳金額',
        reconciliationamount: '已對帳含稅金額',
        discountamount: '折讓含稅金額',
        amountreceivable: '應收帳款金額',
        thismonth:'本月',
        thisseason:'本季',
        thisyear:'今年',
        custom:'自定義'
    },
    TrackingProducts:{//發貨產品入庫追蹤
        orderbillno:'發貨單號',
        material:'產品',
        fromBillNo:'來源訂單號',
        MTONo:'MTO',
        BatchNo:'批號',
        MaterialId:'產品代碼',
        MaterialName:'產品名稱',
        MaterialSpec:'產品規格',
        CarrySQty:'送貨數量',
        ReceivingSQty:'收貨數量',
        CheckState:'檢驗狀態',
        GradeSQty:'合格數量',
        BadnessSQty:'不合格數量',
        CheckResult:'判定結果',
        StoreConfirmSQty:'已入庫量',
        UnStoreConfirmSQty:'未入庫量',
        FromBillNo:'來源訂單號'
    },
    SupplyAssessment: { //供貨考核表
        product: '產品',
        messagedate: '採購日期',
        inputpls: '請輸入產品代碼或名稱',
        inputTxt:'請輸入',
        inputchoose: '請選擇時間',
        yearmonth: '年月',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        purchasenumber: '採購批數',
        deliverablenumber: '應交貨批數',
        receiptnumber: '收貨批數',
        cancelnumber: '退貨批數',
        overduenumber: '逾期批數',
        specialnumber: '特採批數',
        rejectionnumber: '驗退批數',
        overdueproportion: '逾期比例（%）',
        cancelproportion: '退貨比例（%）'
    },
    DeliverDelay: { //交貨延遲分析表
        product: '產品',
        messagedate: '預交貨日',
        inputpls: '請輸入產品代碼或名稱',
        inputTxt:'請輸入',
        inputchoose: '請選擇時間',
        messagenumber: '單據編號',
        date: '單據日期',
        codeid: '產品代碼',
        name: '產品名稱',
        specifications: '產品規格',
        allnumber: '總數量',
        wareday: '最近入庫日',
        stockquantity: '已入庫量',
        delayday: '延遲天數'
    },
    Download: { //下載中心
        inputpls: '請輸入',
        name: '名稱',
        type: '分類',
        operation: '操作',
        intro: '簡介',
        date: '日期',
        download: '下載',
        recupdate: '附件最近上傳時間',
        accessoryname: '附件名稱',
        orderinprogress: '進行中的訂單',
        viewall: '查看所有',
        viewalldrawings: '查看所有圖紙',
        viewchange: '只查看變更',
        viewchangedrawings: '只查看變更圖紙',
        batchdownall: '批次下載所有圖紙',
        batchdownchange: '批次下載變更圖紙',
        clickdownload: '點擊下載',
        downloadall: '批量下載',
        To: '至',
        unselecttip:'您選擇的訂單暫未有變更圖紙！',
        noacc:'無附件',
        number:'序號',
        batchdownacc:'下載所有附件',
        nopower:'無下載權限'
    },
    HelpCenter: { //幫助中心
        inputpls: '請輸入',
        name: '名稱',
        billNo:'單據編號',
        type: '分類',
        operation: '操作',
        date: '上傳時間',
        updatetime: '更新時間',
        publishtime: '發佈時間'
    },
    BulletinCenter:{//公告中心
        inputpls: '可搜索主題名稱或公告內容',
        sysbulletin:'系統公告',
        salbulletin:'系統更新',
        epbulletin:'企業通訊',
        publisher:'發佈人',
        maintitle:'主題',
        keyword:'關鍵字',
        uplaodtime:'上傳時間',
        sendtime:'發送時間'
    },
    Report:{
        qualified:'合格',
        disqualification:'不合格',
        other:'其他',
        withouttime:'時間範圍不允許為空'
    }
}