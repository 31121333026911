import Vuex from "vuex"
import Vue from "vue"
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
    actions:{
        getDeiverCarCount(context,that){
            var count = 0;
            that.axiosInvoke("/SupplierComm/Handler/VueSysHandler.ashx",
            {action:"getdelivercount"},
            (res)=>{
                var data = eval(res.data)
                if(data){
                    count=data[0].totalCount;
                }else count = 0;
                context.commit("GETDELIVERCOUNT",count)
            }
            )
        },
        deleteCarRow(context,index){
            //..提交数据库
            context.commit("DELETECARROW",index)
        },
    },
    mutations:{
        SETCONFIGFIELD(state,ay){ //设置通过供应商平台配置取回的字段值
            for(let i=0;i<ay.length;i++){
                let fieldName = Object.keys(ay[i]);
                let fieldVal = Object.values(ay[i]);
                if(fieldName!='undefined' && fieldVal != 'undefined')
                state[fieldName[0]] = fieldVal[0];
            }
           
        },
        GETDELIVERCOUNT(state,count){
            state.deliverCarCount = count;
        },
        DELETECARROW(state,index){
            state.deliverCar.splice(index,1)
        },
        ERRORTIP(state,dialog){
            state.dialog = dialog
        },
        TIPPOP(state,tippop){
            tippop.visible = !tippop.close;
            state.tippop =tippop;
        },
        KEEPALIVE(state,timer){
            state.keepalivetimer = timer;
        },
        GETPAGESIZE(state,pagesize){
            state.pagesize = pagesize;
        }
    },
    state:{
        keepalivetimer:null,
        deliverCarCount:0,
        dialog:"",
        tippop:{visible:false,popType:'success',popMessage:''},
        exportExcelState:true,
        exportExcelDetailState:false,
        // ADV202306010014 新版供应商平台配置，增加参数配置栏位【不同收货地址产生不同收货申请单】、是否显示【回复交期】、是否显示【发货基本单位】
        // 不同地址产生不同收货申请单、支持供应商变更交期、显示基本单位、显示MTO
        // ReconciliationConfigNo、GenrateDifferentTypes、AllowChangeOfDeliveryDate、ShowBasicUnit、ShowMTO
        ReconciliationConfigNo: false,
        GenrateDifferentTypes: false,
        AllowChangeOfDeliveryDate: true,
        ShowBasicUnit: false,
        ShowMTO: false,
        pagesizes:[{pagesize:10},{pagesize:50},{pagesize:100}],
        pagesize:localStorage.getItem("pagesize")?Number(localStorage.getItem("pagesize")):10,
    },
    plugins: [createPersistedState({
        storage:window.sessionStorage,  // 同localStorage相同，只是将vuex的所有值存储到sessionStorage中
        reducer(val){
            return {
                deliverCar:val.deliverCar
            }
        }
    })]
})